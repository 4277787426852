<template>
  <div class="title-area">
    <div class="title2 ellipsis">
      {{title}}
    </div>
    <div class="arrow-right-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
        <path d="M1 1L9 9L1 17" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleWithIcon",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
.title-area{
  display: flex;
  width: auto;
  height: 48px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  color: var(--Grayscale-Gray1);
}

.arrow-right-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<template>
  <div class="related-box-list" v-safe-area-bottom>
    <div class="desc-title">
      <div class="title2">연관 추천</div>
    </div>

    <div class="thumbnail-grid">
      <ThumbnailImageDynamic
          v-for="tarotBoxId in tarotBoxIds" :key="tarotBoxId"
          :src="getThumbnailUrl(tarotBoxId)"
          :image-width="thumbnailImageWidth"
          @click="handleClickTarotBox(tarotBoxId)" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import ThumbnailImageDynamic from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageDynamic.vue";

export default {
  name: "TarotDetailRelatedBoxList",
  components: {ThumbnailImageDynamic},
  inject: ["eventBus"],
  computed: {
    ...mapGetters(['tarotBoxes']),
    thumbnailImageWidth() {
      return `${(this.windowWidth - 32 - 10) / 2}px`;
    }
  },
  props: {
    tarotBoxIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    handleClickTarotBox(tarotBoxId) {
      this.eventBus.emit('openTarotDetail', tarotBoxId);
      logEvent('snack_detail_recommend_box_click', {
        'tarot_box_id': tarotBoxId,
        'tarot_box_title': this.tarotBoxes.find(box => box.id === tarotBoxId).title
      });
    },
    getThumbnailUrl(tarotBoxId) {
      const box = this.tarotBoxes.find(box => box.id === tarotBoxId);
      return box ? box.thumbnailImgUrl : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2';
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.related-box-list {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
  padding: 30px 16px 30px 16px;
  margin-bottom: 40px;
  text-align: left;
}

.desc-title {
  margin-bottom: 10px;
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  overflow-x: scroll;
}
</style>
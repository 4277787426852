<template>
  <div class="category-container">
    <div class="category-items">
      <div class="category-item"
           v-for="category in visibleCategories"
           :key="category.id"
           @click="handleIconClick(category)">
        <ShimmerIcon
            :src="require(`@/tarot_box/pages/home/assets/categories/${category?.id}-min.png`)"
            :heightRatio="1"
            :imageStyle="{width: '48px'}">
        </ShimmerIcon>
        <p class="caption1">{{ category.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShimmerIcon from "@/tarot_box/components/shimmer/ShimmerIcon.vue";
import {logEvent, showDailyFortune} from "@/tarot_box/helper/native_api";

export default {
  name: "MainCategory",
  components: {
    ShimmerIcon,
  },
  inject: ["eventBus"],
  data() {
    return {
    };
  },
  props: {

  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
    visibleCategories() {
      let filteredCategories = this.iconCategories;

      // 웹마이타로일 때, 무료운세 아이콘 제외
      if (this.$isWeb()) {
        filteredCategories = filteredCategories.filter(category => category.id !== 9);
      }

      return filteredCategories
          .slice(0, 100) // 최대 100개의 아이템만 고려
          .sort((a, b) => b.priority - a.priority); // 우선순위에 따라 정렬
    },
  },
  methods: {
    handleIconClick(category) {
      logEvent('home_icon_menu_click',{
        category_id: category.id,
        category_name: category.title,
      });

      let queryParams = { ...this.$route.query };

      if(category.linkType === 'daily_fortune') {
          const queryParams = { ...this.$route.query };
          const buildNumber = parseInt(queryParams.build_number);
          if(buildNumber >= 84){
            this.$router.push({path: '/tarot_box/today_tarot', query: queryParams})
          } else {
            showDailyFortune();
          }
      }else if(category.linkType === 'event1'){
        this.$router.push({path: '/tarot_box/event', query: queryParams})
      }else if(category.linkType === 'event2'){
        this.$router.push({path:'/tarot_box/event2', query: queryParams})
      }
      else {
        const categoryKey = category.linkType+'/'+category.title;
        this.eventBus.emit('openCategoryPage', categoryKey);
      }
    },
  },
}

</script>

<style scoped>
.category-container {
  display: flex;
  justify-content: center; /* 아이템들을 중앙 정렬 */
  align-items: center;
  margin-top: 22px; /* 위쪽 여백 추가 */
  margin-bottom: 16px; /* 아래쪽 여백 추가 */
  width: 100%;
}

.category-items{
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* 아이템들이 줄 바꿈 */
  justify-content: flex-start; /* 아이템들을 중앙 정렬 */
}

.category-item {
  flex: 0 0 17.61%;
  text-align: center; /* 텍스트를 중앙 정렬 */
  margin: 0 1.18%; /* 상하좌우 여백 */
  margin-bottom: 4px; /* 아래쪽 여백 추가 */
  display: flex;
  flex-direction: column; /* 아이템 내부 요소를 세로로 배치 */
  align-items: center; /* 아이템 내부 요소를 가운데 정렬 */
}
</style>
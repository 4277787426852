<template>
  <div class="heading-container">
    <div class="heading-texts gray1">
      <span class="font14 gray5"
      ><strong>{{ checkAppendixIndex }}</strong></span
      >
      <span style="width: 90vw; word-break: keep-all" class="serif font20">{{ checkAppendix }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitlePage",
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    }
  },
  computed: {
    checkAppendix() {
      if(this.currentPageData.page_title === '부록'){
        return '속시원한 심층풀이'
      } else {
        return this.currentPageData.page_title
      }
    },
    checkAppendixIndex(){
      if(this.currentPageData.page_title === '부록'){
        return '부록'
      } else {
        return `Chapter ${this.currentChapterIndex}`
      }
    }
  }
}
</script>

<style scoped>
.heading-container{
  width: 100vw;
  height: 100%;
  transform: translateY(-20px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.heading-texts{
  display: flex;
  transform: translateY(-30px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;

}
</style>
<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div v-if="requestSuccessful && !isPaid" class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          심층풀이 질문완료!
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          심층풀이 해석을 열심히 만들고 있어요⏳<br>
          금방 준비가 되지만 알림톡으로도 알려드리니<br>
          다른일을 하셔도 좋습니다👌
        </span>
      </div>

      <div v-else-if="requestSuccessful && isPaid" class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          심층풀이 질문완료!
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          심층풀이 해석이 진행되고 있어요<br>
          앞으로 <strong>남은 횟수는 {{Math.max(userData.extra_question_ticket - 1, 0)}}번</strong> 이에요.
        </span>
      </div>

      <div v-else class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          해석을 진행할까요?
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          해석이 진행되면 질문은
          <span style="color: var(--Color-palette-System-color-Red)">
             <strong>수정이 불가능해요.</strong>
          </span>
          <br>해당 질문으로 해석을 받으시겠어요?
        </span>
      </div>
      <div v-if="requestSuccessful" class="popup-footer">
        <div class="btn" @click="handleCancel" style="background: var(--Color-palette-Primary-Default)">
          확인
        </div>
      </div>
      <div v-else class="popup-footer">
        <div class="btn gray" @click="handleCancel">
          취소
        </div>
        <div class="btn" @click="submitextraRequest" style="background: var(--Color-palette-Primary-Default)">
          해석받기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogPopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    requestSuccessful: {
      type: Boolean,
      default: false,
    },
    extraQuestionStatus: {
      type: String,
      default: "",
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    isPaid: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close', 'request'],
  watch: {
    requestSuccessful(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.closeConfirmPopupCallback();
      }
    }
  },
  methods: {
    handleCancel() {
      this.closeConfirmPopupCallback();
    },
    async closeConfirmPopupCallback() {
      this.$emit('close');
    },
    submitextraRequest() {
      this.$emit('request');
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc( 100vw - 40px);
  padding: 52px 0 16px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 16px;
  justify-content: space-between;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}

.gray{
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray3);
}
</style>
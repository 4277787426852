<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="modal-background" @click="backgroundClick"></div>

    <div class="modal-content">
      <button class="modal-close is-large" aria-label="close" @click="closePopupCallback">
      </button>
      <slot></slot>
    </div>
  </div>


  <div class="x-mark" @click="backgroundClick">
    <img src="@/tarot_box/pages/home/components/tarot_box_detail/assets/images/x-mark.png" alt="x-mark">
  </div>



</template>

<script>
export default {
  name: "ImagePopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    closePopupCallback: {
      type: Function,
      required: true,
    },
    disableOutsideClick: { // 바깥 클릭 이벤트를 비활성화할지 결정하는 속성
      type: Boolean,
      default: false,
    }
  },
  methods: {
    backgroundClick() {
      if (!this.disableOutsideClick) {
        this.closePopupCallback();
      }
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: auto;
  z-index: 9999 !important;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.x-mark{
  position: fixed;
  top: 30px;
  right: 30px;
}

.x-mark img{
  width: 20px;
  height: 20px;
}

.modal-content {
  position: relative;
  width:88.8%;
  margin: auto;
  max-width: 480px;
  max-height: calc(var(--vh, 1vh) * 80);
  overflow: visible;
  transform: translate(0,0);
  background-color: transparent;
  border-radius: 13px;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 16px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #bbbbbb;
  border-radius: 50%;
  padding: 5px 10px;
  line-height: 1;
  outline: none;
}


h1 {
  font-size: 1.375rem;
  font-weight: 900;
  margin: 0 0;
}

h2 {
  font-size: 1.375rem;
  font-weight: 700;
  margin: 0 0;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0;
}

h4 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0;
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 4px 0;
  color: #808080;
}

.popup_footer{
  display: flex;
  padding: 0px 0px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.popup_btn {
  width: 48%;
  height: 48px;
  background-color: #5F0080;
  border-color: transparent;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}
.popup_btn_grey{
  width: 48%;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #5F0080;
  border-radius: 8px;
  color: #5F0080;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.popup_btn_fade{
  width: 48%;
  height: 48px;
  background-color: #E7E5E5;
  border: none;
  border-radius: 8px;
  color: #696969;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.popup_btn_yellow{
  width: 48%;
  height: 48px;
  background-color: #FEE555;
  border-color: transparent;
  border-radius: 8px;
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.popup {
  width: 100%;
  max-width: 480px;
  background-color: #FFFFFF;
  border-radius: 0.875rem;
  padding: 20px;
  box-sizing: border-box;
}

.popup_header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.popup_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}


.popup_btn_small{
  width: 100%;
  height: 20px;
  background-color: #ffffff;
  border-color: transparent;
  color: #000000;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}

.popup_btn_large{
  width: 100%;
  height: 52px;
  background-color: #5F0080;
  border-color: transparent;
  border-radius: 12px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

</style>

<template>
  <div class="desc-container">
    <div class="desc-title">
      <div class="title2">소개</div>
    </div>

    <div
        class="desc-description"
        :class="{'expanded': isContentExpanded}"
        :style="descStyle"
        ref="descContent"
    >
      <div class="text4" v-html="parseText(selectedTarotBox?.description)"></div>
    </div>
    <button
        v-if="!isContentExpanded && isContentTooLong"
        class="buttonL"
        @click="toggleContent"
    >
      더보기
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 9.5L12 14.5L7 9.5" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "TarotDetailDescArea",
  components: {},
  data() {
    return {
      isContentExpanded: false,
      isContentTooLong: false,
      lineHeight: 24, // 가정된 line-height 값
      maxLines: 10, // 최대 줄 수
    };
  },
  emits: ['content-too-long'],
  props: {
    selectedTarotBox: {
      type: Object,
    },
    imageLoaded: {
      type: Boolean,
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkContentLength);
    this.checkContentLength();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkContentLength);
  },
  watch: {
    $route(to, from) {
      if (from.query.tarot_box_id && to.query.tarot_box_id !== from.query.tarot_box_id) {
        console.log('Route changed with different tarot_box_id');
        this.checkContentLength();
      }
    }
  },
  computed: {
    ...mapGetters(["tarotBoxes"]),
    descStyle() {
      if (this.isContentExpanded) {
        return {};
      }
      return {
        "max-height": `${this.lineHeight * this.maxLines}px`,
      };
    },
  },
  methods: {
    parseText(text) {
      if (text) {
        return text.replace(/\\n/g, "<br>");
      } else {
        return "";
      }
    },
    toggleContent() {
      this.isContentExpanded = !this.isContentExpanded;
      logEvent('snack_detail_readmore_click', {
        "tarot_box_id": this.selectedTarotBox.id,
        'tarot_box_name': this.selectedTarotBox.title,
        'long_enough': this.isContentTooLong
      });
    },
    checkContentLength() {
      this.isContentExpanded = true; // 임시로 확장
      setTimeout(() => {
        if(this.$refs.descContent === undefined || this.$refs.descContent === null || this.$refs.descContent ==='') {
          return;
          //DOM을 그리기 전 렌더링이 되어서 clientHeight가 null로 나오는 경우 그냥 펼쳐줌
        } else {
          const contentHeight = this.$refs.descContent.clientHeight;
          this.isContentExpanded = false; // 원래 상태로 복원
          this.isContentTooLong = contentHeight > this.lineHeight * this.maxLines + 24
          this.$emit('content-too-long', this.isContentTooLong);
          }
        }, 0);
    }
  },
};
</script>


<style scoped>
.desc-container {
  width: calc(100% - 32px);
  padding: 30px 16px;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  text-align: left;
}

.desc-title {
  margin-bottom: 10px;
}

.desc-description {
  position: relative; /* 이 부분을 추가 */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  padding-bottom: 5px;
}

.desc-description.expanded {
  max-height: none;
}

.buttonL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0; /* 크고 편안한 패딩 */
  color: var(--Color-palette-Primary-Default);
  background-color: var(--Grayscale-Gray12);
  border: 1px solid var(--Color-palette-Primary-Default);
  border-radius: 8px;
  margin-top: 24px;
}
</style>
<template>
  <div class="review-page-item" :class="{ 'highlighted': isHighlighted }">
    <div class="title4">
      {{emojiset[review.user_reaction]}}
    </div>
    <div class="text2">
      {{review.user_review}}
    </div>
    <div class="text2" style="color:var(--Grayscale-Gray6)">
      {{ maskedDisplayName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewPageItem",
  components: {},
  props: {
    review: {
      type: Object
    },
    isHighlighted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    maskedDisplayName() {
      if (this.review.display_name) {
        if (this.review.display_name.length <= 2) {
          // 두 글자 이하인 경우 마지막 글자만 마스킹
          return this.review.display_name.slice(0, -1) + '*';
        } else {
          // 두 글자 초과인 경우 앞의 두 글자만 남기고 나머지 마스킹
          const visiblePart = this.review.display_name.substring(0, 2);
          const maskedPart = '*'.repeat(this.review.display_name.length - 2);
          return visiblePart + maskedPart;
        }
      }
      return '';
    },
    emojiset() {
      return {
        '재밌어요': '😊 재밌어요',
        '도움 돼요': '🙏 도움 돼요',
        '그냥 그래요': '👀 그냥 그래요',
        '별로네요': '👎 별로네요'
      };
    },
  },
  methods: {
  },
}

</script>

<style scoped>
.review-page-item{
  display: flex;
  padding: 16px;
  margin: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: 0.5px solid var(--Grayscale-Gray9, #D9D9D9);
  background: var(--Grayscale-Gray12, #FFF);
}

.highlighted {
  animation: highlightEffect 1.5s;
}

@keyframes highlightEffect {
  0%, 100% {
    filter: none; /* 효과 색상과 크기 조정 */
  }
  50% {
    filter: drop-shadow(0 0 2px var(--Color-palette-Primary-Lighten1)); /* 효과 색상과 크기 조정 */
  }
}


</style>
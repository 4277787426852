<template>
  <div v-web class="footer-disclaimer">
    <p><strong>결제 전 꼭 확인해주세요!</strong></p>
    <p>결제 및 환불 안내</p>
    <ul>
      <li>선택하신 결제 수단으로 결제를 완료하시면 인증하신 휴대폰으로 카카오 알림톡을 보내 드립니다.</li>
      <li>콘텐츠의 해석을 생성하는데 짧게는 5분, 길게는 30분 정도 소요 될 수 있습니다.</li>
      <li>콘텐츠 해석이 완료 되면 인증하신 휴대폰으로 해석을 확인 하실 수 있는 URL을 보내 드립니다.</li>
      <li>구매하신 콘텐츠는 "마이타로 앱"에 가입하시면 계속 보실 수 있습니다.</li>
      <li>결제 후 결과가 전송 된 콘텐츠는 환불 되지 않으며, 마이타로 서비스 이용정책을 따릅니다.</li>
      <li>결제 관련 문의 : dev@1zlabs.com</li>
    </ul>
  </div>
  <div v-web class="footer-links">
    <p>주식회사 원지랩스 ㅣ 대표 곽근봉 ㅣ 사업자등록번호 4388802481 ㅣ</p>
    <p>통신판매업신고번호 2022-서울강남-03521</p>
    <p>서울특별시 강남구 삼성동 107</p>
    <p>연락처 070-7878-4506 l 고객센터 dev@1zlabs.com</p>
    <div class="link-list">
      <p @click="openInNewTab('https://terms.1zlabs.com/mytarot_privacy/')"><strong>개인정보 처리방침</strong></p>
      <p @click="openInNewTab('https://terms.1zlabs.com/mytarot_service/')"><strong>이용약관</strong></p>
      <p @click="openInNewTab('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4388802481')"><strong>사업자정보확인</strong></p>
    </div>
    <p>Copyright 1z labs Inc. 2024</p>
  </div>
</template>

<script>
export default{
  name: "TarotDetailFooterForWeb",
  methods : {
    openInNewTab(url) {
      if (url) {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus();
        } else {
          console.error('Failed to open the new tab. The popup might have been blocked.');
        }
      } else {
        console.error('Invalid URL:', url);
      }
    }
  }
}
</script>

<style scoped>
.footer-disclaimer {
  width: 100%;
  background-color: var(--Grayscale-Gray11);
  color: var(--Grayscale-Gray5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 24px 16px 16px 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;
}

.footer-disclaimer ul{
  padding-inline-start: 20px;
  word-break: break-all;
  font-size: 13px;
}

.footer-links{
  width: 100%;
  background-color: #EDEDED;
  color: var(--Grayscale-Gray5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 24px 16px 80px 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.2;
}

.footer-links p{
  font-size: 12px;
}

.link-list{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  font-weight: 800;
  margin: 10px 0;
}

ul{
  margin-top: 12px;
}


</style>
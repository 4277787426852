<template>
  <div class="card-scroll-container">
    <img v-for="(cardNumber,key) in tarotCards" :key="key" @click="selectCard(cardNumber)"
         :src="cardBackImage" class="card scrollable-card" alt="Card Back"
         :class="{dimmed: cards.includes(cardNumber)}"/>
  </div>
</template>

<script>
import cardBackImage from '@/tarot_box/pages/box_input/assets/images/TarotCardSelectPage/card_back.png';
import {delay} from "@/common/utils";

export default {
  mounted() {
    this.eventBus.on("shuffleTarotCards", () => {
      this.shuffleCards();
    });
    this.tarotCards = [...Array(this.$constants.tarotCardCount).keys()];
  },
  inject: ['eventBus'],
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      cardBackImage: cardBackImage,
      tarotCards: [],
      isShuffling: false
    };
  },
  methods: {
    selectCard(selectedCardIndex) {
      if(this.cards.includes(selectedCardIndex) || this.isShuffling || this.cards.every(card => card !== null)) return;
      this.$emit('selectCard', selectedCardIndex);
    },
    async shuffleCards() {
      if (this.isShuffling) return;
      this.isShuffling = true;

      // 카드 더미 생성
      let cardElements = document.querySelectorAll('.card.scrollable-card');
      let cardStack = Array.from(cardElements);
      let initialPositions = cardStack.map(card => card.getBoundingClientRect());

      // 카드 더미 왼쪽으로 모음
      cardStack.forEach((card, index) => {
        card.style.transition = 'transform 300ms ease-in-out';
        card.style.transform = `translate(${-(initialPositions[index].left)}px)`;
        card.style.boxShadow = 'none';
      });

      await delay(500);

      // 카드 더미 섞기
      let currentIndex = this.tarotCards.length, temporaryValue, randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        temporaryValue = this.tarotCards[currentIndex];
        this.tarotCards[currentIndex] = this.tarotCards[randomIndex];
        this.tarotCards[randomIndex] = temporaryValue;
      }

      // 카드 더미 펼침
      cardStack.forEach((card) => {
        card.style.transition = 'transform 500ms ease-in-out';
        card.style.transform = `translate(0px)`;
        card.style.boxShadow = '0 4px 12px 0 rgba(0, 0, 0, 0.46)';
      });

      await delay(500);
      this.isShuffling = false;
      return this.tarotCards;
    },
  }
}
</script>

<style scoped>
.card-scroll-container {
  display: flex;
  overflow-x: scroll;
}

.card {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.46);
  z-index: 0;
}

.scrollable-card {
  width: 80px;
  border-radius: calc(80px * 0.08);
  margin-left: -40px;
  transition: filter 0.15s ease;
}

.scrollable-card:first-child{
  margin-left: 0;
}

.dimmed {
  filter: brightness(50%);
}
</style>
<template>
  <div class="paywall">
    <Loading v-model:active="isLoading"
             loader="spinner"
             color="#1E2352"
             :opacity="0.7"
             :z-index="99999">
    </Loading>
    <CloverResultPopup
        :is-success="isSuccess"
        :clover="pickedPrice"
        @close="closePopup"
        :is-active="isResultPopupActive"/>
    <div class="paywall-header" v-safe-area-top>
      <img style="transform: rotate(180deg)" src="@/my_page/assets/next.svg" @click="handleBack">
      <div class="title4">클로버 충전소</div>
      <div style="width: 16px; height: 16px;"/>
    </div>

    <div class="paywall-wrapper" v-safe-area-top>
      <div class="clover">
        <div class="my-clover">
          <div class="text2">나의 보유 클로버</div>
          <div class="title2 clover-status">
            {{ formatNumber(userClover) }} <img src="@/tarot_box/pages/box_input_v2/assets/clover.svg">
          </div>
        </div>
      </div>
      <div class="clover-price">
        <div class="clover-price-header">
          <div class="title3">충전 클로버</div>
        </div>
        <div v-for="item in sortedPaywallItems" :key="item.priority" class="clover-tier" @click="requestAppPurchase(item.product_id, item.clover_count, item.price, item.name)">
          <div class="price">
            <div class="title2" style="display: flex; align-items: center; text-align: left; gap: 6px; justify-content: flex-start">
              {{item.clover_count}}
              <img src="@/tarot_box/pages/box_input_v2/assets/clover.svg">
            </div>
            <div v-if="item.price !== item.original_price"
                 style="color: var(--Color-palette-System-color-Red); font-size: 12px; font-weight: bold;">
              💸&nbsp;{{formatNumber(item.original_price - item.price)}}원 아껴요
            </div>
          </div>
          <div class="won">
            <div v-if="item.price !== item.original_price"
                 style="text-decoration: line-through; color: var(--Grayscale-Gray6)"
                 class="text4">&nbsp;
              {{formatNumber(item.original_price)}}&nbsp;
            </div>
            <div class="title3">₩{{formatNumber(item.price)}}</div>
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <div class="title3 gray6">클로버 이용안내</div>
        <ul>
          <li>클로버 충전 후 7일 이내, 사용하지 않은 경우에만 결제 취소가 가능합니다. 결제를 통해 충전된 클로버의 일부라도 사용하시는 경우 전체에 대하여 결제 취소가 불가능합니다.</li>
          <li>법정대리인의 동이 없는 미성년자의 결제는 취소될 수 있습니다.</li>
          <li>클로버 결제, 사용 내역은 마이페이지 화면 내 클로버 내역 화면에서 확인할 수 있습니다.</li>
          <li>위 표기된 결제 금액은 부가가치세(10%)가 포함된 금액입니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CloverResultPopup from "@/paywall/component/CloverResultPopup";
import {getTarotUserInfo, recordLastClickedItem, updateAppPurchaseReferrer} from "@/tarot_box/helper/mytarot_api";
import {mapGetters} from "vuex";
import Loading from "vue-loading-overlay";
import {hideBottomBar, logEvent, showBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "CloverPaywall.vue",
  inject:['appListener', 'appCommander'],
  components: {
    Loading,
    CloverResultPopup
  },
  data() {
    return {
      userClover: 0,
      userId: 0,
      userPhoneNumber: '',
      osType: '',
      price: {
        clover: 399,
        discount: 6000,
        originalPriceWon: 170000,
        salePriceWon: 110000
      },
      pickedPrice: 0,
      isResultPopupActive: false,
      purchaseSubscription: null,
      isSuccess: false,
      isLoading: false,
    };
  },
  props: {
    isTeleported: {
      type: Boolean,
      default: false
    },
    restoreBottomBar: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    hideBottomBar();
    logEvent ('paywall_into', {});
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');
    this.getUserData();
    window.onBackButtonClick = this.handleBack;
  },
  unmounted() {
    this.purchaseSubscription?.unsubscribe();
  },
  computed: {
    ...mapGetters(['paywall']),
    sortedPaywallItems() {
      return [...this.paywallItems].sort((a, b) => b.priority - a.priority);
    },
    paywallItems() {
      if (this.osType === 'aos' && this.paywall && this.paywall.android) {
        return this.paywall.android;
      } else if (this.osType === 'ios' && this.paywall && this.paywall.ios) {
        return this.paywall.ios;
      }
      return [];
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getUserData() {
      let res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.userClover = res.data.clover_balance;
      }
    },
     requestAppPurchase(productId, price, priceWon, productName) {
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const utmContent = urlParams.get('utm_content');

      this.isLoading = true;
      recordLastClickedItem(this.userPhoneNumber, this.userId, priceWon, price, productName);
      logEvent('paywall_item_click', {product_id: productId})
      this.purchaseSubscription?.unsubscribe();
      this.purchaseSubscription = this.appListener.subscribeSingleAction('purchase_result', (data) => {
        if (data.result === 'success') {
          this.isSuccess = true;
          this.isResultPopupActive = true;

          let res = updateAppPurchaseReferrer(this.userId, this.userPhoneNumber, utmContent);
          if (res.result === 'success') {
            console.log('updateAppPurchaseReferrer success');
          }
          logEvent('paywall_success_popup', {})
        } else if (data.result === 'cancel') {
          logEvent('paywall_cancel_popup', {})
          this.isSuccess = false;
          this.isLoading = false;
        } else {
          this.isSuccess = false;
          this.isResultPopupActive = true;
        }
        this.isLoading = false;
      });
      this.pickedPrice = price;
      console.log(`requestAppPurchase: ${productId}`);
      this.appCommander.callAppAction('request_purchase', { product_id: productId });
    },
    handleBack() {
      if (this.isTeleported) {
        this.$emit('close-clover');
        if (this.restoreBottomBar) {
          showBottomBar();
        }
      } else {
        showBottomBar();
        this.$router.go(-1);
      }
    },
    async closePopup() {
      await this.getUserData();
      this.isLoading = false;
      this.isResultPopupActive = false;
      this.$emit('close-clover');
      if (this.restoreBottomBar) {
        showBottomBar();
      }
    }
  }
}
</script>

<style scoped>

.title3, .title2, .price{
  font-family: "SUIT Variable", sans-serif;
}

.paywall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 99999;
}

.paywall-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 20px 24px;
  margin: 20px 0 0 0;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}

.paywall-wrapper {
  margin-top: 56px;
  height: calc(100vh - 56px);
  overflow-y: auto;
}

.my-clover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--Grayscale-Gray11);
  border: 1px solid var(--Grayscale-Gray10);
}

.clover-price{
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
}

.clover-price-header{
  display: flex;
  padding: 16px 0;
}

.clover-tier{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-Gray10);
}

.price{
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.won{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.disclaimer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  padding: 32px 24px 60px 24px;
  font-size: 12px;
  color: var(--Grayscale-Gray6);
  background: var(--Grayscale-Gray10);
}

.disclaimer ul{
  padding: 30px 0 0 24px;
  text-align: left;
}

.disclaimer li{
  margin-bottom: 8px;
  line-height: 150%;
}
</style>

<template>
  <div class="ranking-wrapper">
    <TitleOnlyText title="마이타로 BEST"/>
    <div class="ranking-content">
      <RankingChips @update-category="handleCategoryUpdate"/>
      <RankingItems :selectedCategory="selectedCategory"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RankingChips from "./RankingChips";
import RankingItems from "./RankingItems";
import TitleOnlyText from "@/tarot_box/pages/home/components/common/title/TitleOnlyText.vue";
export default {
  name: "MainRanking",
  components: {
    TitleOnlyText,
    RankingChips,
    RankingItems,
  },
  data() {
    return {
      selectedCategory: '종합'
    };
  },
  props: {
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
  },
  methods: {
    handleCategoryUpdate(selectedCategory) {
      this.selectedCategory = selectedCategory;
    }
  }
}

</script>

<style scoped>
.ranking-wrapper{
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  padding-left: 16px;
  margin-bottom: -10px;
}
</style>
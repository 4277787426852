<template>
  <div class="grid-area">
    <div class="grid-area-margin"/>
    <div class="selected-card-grid-area" ref="cardGridRef">
      <div class="selected-card-grid" :style="calculateCardGridStyle(standardRatio)">
        <div v-for="(card, index) in cardPositionData" :key="index" class="card-slot" :class="{ highlighted: (index === nextCardIndex) && !selectedCards[index] }"
             :style="calculateCardSlotStyle(card,standardRatio)">
          <img v-if="selectedCards[index]!==null"
               :src="cardBackImage" class="card" alt="Card Back"
               :style="calculateSelectedCardImageStyle(card,standardRatio)"/>
          <div v-else class="text-area title5">
            {{ card.card_order + '\n' + '카드'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardBackImage from '@/tarot_box/pages/box_input/assets/images/TarotCardSelectPage/card_back.png';
export default {
  mounted() {
    window.addEventListener('resize', this.calculateStandardRatio);
    this.calculateStandardRatio();
  },
  props: {
    selectedCards: {
      type: Array,
      required: true
    },
    nextCardIndex: {
      type: Number,
      required: true
    },
    cardPositionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      cardBackImage: cardBackImage,
      standardRatio: 0,
    };
  },
  methods: {
    calculateStandardRatio() {
      const areaWidth = this.$refs.cardGridRef.clientWidth;
      const areaHeight = this.$refs.cardGridRef.clientHeight;
      const standardWidth = this.$constants.tarotCardGrid.width;
      const standardHeight = this.$constants.tarotCardGrid.height;

      const widthRatio = areaWidth / standardWidth;
      const heightRatio = areaHeight / standardHeight;

      this.standardRatio = widthRatio < heightRatio ? widthRatio : heightRatio;
    },
    calculateCardGridStyle(standardRatio) {
      const standardWidth = this.$constants.tarotCardGrid.width;
      const standardHeight = this.$constants.tarotCardGrid.height;
      const gridWidth = standardWidth * standardRatio;
      const gridHeight = standardHeight * standardRatio;

      return {
        width: `${gridWidth}px`,
        height: `${gridHeight}px`,
      };
    },
    calculateCardSlotStyle(card,standardRatio) {
      const cardWidth = card.card_display_size * standardRatio;
      const cardHeight = card.card_display_size * this.$constants.tarotCardAspectRatio * standardRatio;
      const positionCorrectionConstant = card.orientation === 'horizontal' ? (card.card_display_size * this.$constants.tarotCardAspectRatio - card.card_display_size)/2 : 0;
      const cardLeft = (card.axis_x + positionCorrectionConstant) * standardRatio;
      const cardTop = (card.axis_y - positionCorrectionConstant) * standardRatio;
      return {
        width: `${cardWidth}px`,
        height: `${cardHeight}px`,
        transform: card.orientation === 'horizontal' ? 'rotate(-90deg)' : '',
        position: 'absolute',
        left: `${cardLeft}px`,
        top: `${cardTop}px`
      };
    },
    calculateSelectedCardImageStyle(card,standardRatio) {
      const cardWidth = card.card_display_size * standardRatio;
      const cardBorderRadius = cardWidth * 0.08;
      return {
        width: `${cardWidth}px`,
        borderRadius:`${cardBorderRadius}px`
      };
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateStandardRatio);
  }
}
</script>

<style scoped>
.grid-area{
  position: relative;
  width: 100vw;
  max-height: calc(var(--vh, 1vh) * 70);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-area-margin{
  width: 100vw;
  height: 60px;
  background-color: transparent;
}

.selected-card-grid-area {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-card-grid {
  position: relative;
  height: 80%;
}

.card {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.46);
}

.card-slot {
  border-radius: 6px;
  border: 0.5px solid #333134;
  background: #331D42;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-area {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 100%;
  color: var(--Color-palette-Primary-Lighten3);
}

.highlighted {
  border: 1px solid #D9B6FC;
  background: #493055;
}
</style>
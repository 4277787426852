<template>
<!-- 변경: BasicPopup 컴포넌트를 사용 -->
  <ImagePopup :isActive="isActive" :closePopupCallback="closePopupCallback">
    <Flicking :options="{
    circular: false,
    bounce: '20%',
    align: 'prev',
    moveType: ['strict', {count: 1}],
    defaultIndex: currentSlideIndex,
    panelsPerView: -1,
     }"
    @moveEnd="handleSlideChange">
      <div v-for="(slide, index) in selectedTarotBox?.screenShotImgUrlList" :key="index" class="carousel_item">
        <ShimmerImage
            :src="slide"
            :imageStyle="{ width: '90vw' }"
            :heightRatio="1.66"
            :isCircle="false"
            :borderRadius="'8px'"/>
      </div>
    </Flicking>
  </ImagePopup>
</template>

<script>
import ImagePopup from "@/tarot_box/pages/home/components/tarot_box_detail/components/ImagePopup";
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import Flicking from '@egjs/vue3-flicking';

export default {
  name: "ScreenshotPopup",
  components: {ShimmerImage, ImagePopup, Flicking},
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    closePopupCallback: {
      type: Function,
      required: true,
    },
    selectedTarotBox: {
      type: Object,
    },
    numberOfCarousel: {
      type: Number,
      required: true,
    },
    currentSlideIndex: {
      type: Number,
    }
  },
  computed: {
    ...mapGetters(['user', 'categories', 'promotions', 'recommendTabs', 'tarotBoxes']),
  },
  methods: {
    noThanks() {
      this.closePopupCallback();
    },
    handleSlideChange() {
      logEvent("snack_detail_shot_navi", {tarot_box_id: this.selectedTarotBox.id});
    }
  },
}
</script>
<style scoped>
.popup{
  width: 90vw;
  height: calc(var(--vh, 1vh) * 90);
  margin: 0;
  padding: 0;
  font-size: 0;
  background-color: transparent;
  border: none;
}

button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00ff95;
  color: #5F0080;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.carousel_item {
  margin: 5vw;
}

</style>

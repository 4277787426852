<template>
  <div class="check-wrapper">
    <div class="proceed-button-wrapper" v-safe-area-bottom>
      <div v-wave class="proceed-button-white" @click="goPrev">이전</div>
      <div v-wave v-if="this.currentIndex +1 === this.totalIndex" class="proceed-button" @click="goNext">완료</div>
      <div v-wave v-else class="proceed-button" @click="goNext">다음 항목으로</div>

    </div>
    <div class="check-container" >
      <div class="sections" v-safe-area-top>
        <InputHeader
            :user-name="userName"
            :is-checker="true"
            :current-index="currentIndex"
            :input-sections="inputSections"/>
        <div class="content-wrapper">
          <div class="answers">
            <div v-for="(answer, index) in formattedUserAnswers[currentIndex - 1]" :key="index" class="answer">
              <div class="answer-title">{{ answer.value }}</div>
            </div>
          </div>
          <div class="saju-table" v-if="shouldShowSajuTable">
            <span class="title3">{{ sajuTableTitle }}</span>
            <div class="saju-table-body">
              <div class="table-header">
                <div class="table-header-item">시주</div>
                <div class="table-header-item">일주</div>
                <div class="table-header-item">월주</div>
                <div class="table-header-item">년주</div>
              </div>
              <div class="saju-body" v-if="currentSajuData.length > 0">
                <div v-for="(row, rowIndex) in formattedCurrentSajuData" :key="rowIndex" class="saju-row">
                  <div v-for="(cell, cellIndex) in row" :key="cellIndex" class="saju-cell">
                    <div class="saju-hanja">{{ cell.hanja }}</div>
                    <div class="saju-hangul">{{ cell.hangul }}</div>
                  </div>
                </div>
              </div>
              <div class="saju-body skeleton" v-else>
                <div v-for="row in 2" :key="row" class="saju-row">
                  <div v-for="cell in 4" :key="cell" class="saju-cell">
                    <div class="saju-hanja skeleton-box"></div>
                    <div class="saju-hangul skeleton-box"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="saju-info" @click="openPopup">
              <img :src="require('@/tarot_box/pages/box_input_v2/assets/question-mark.svg')">
              <span class="buttonM">알고계신 사주간지와 다른가요?</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputHeader from "@/tarot_box/pages/box_input_v2/components/InputHeader.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "InputCheckComponent",
  components: {
    InputHeader,
  },
  props: {
    userName: {
      type: String,
    },
    userAnswers: {
      type: Array,
    },
    inputSections: {
      type: Array,
      required: true,
    },
    sajuData: {
      type: Array,
      default: () => [],
    },
    partnerSajuData: {
      type: Array,
      default: () => [],
    },
    currentIndex: {
      type: Number,
    },
    totalIndex: {
      type: Number,
    },
    tarotBoxData: {
      type: Object,
    },
  },
  data() {
    return {
      openSajuInfoPopup: false,
    };
  },
  mounted() {
    this.initPage();
  },
  computed: {
    formattedUserAnswers() {
      const result = [];
      for (const section of this.inputSections) {
        const sectionAnswers = [];
        for (const input of section.inputs) {
          const answers = this.userAnswers.filter(answer =>
              answer.key === input.key && answer.inputFormType === input.inputFormType
          );
          sectionAnswers.push(...answers);
        }
        result.push(sectionAnswers);
      }
      return result;
    },
    shouldShowSajuTable() {
      if (!this.inputSections || this.currentIndex <= 0) return false;
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return false;

      if (currentSection.sectionType === 'personalInfo') {
        return currentSection.inputs.some(input => input.key === 'saju');
      } else if (currentSection.sectionType === 'partnerInfo') {
        return currentSection.inputs.some(input => input.key === 'saju_partner');
      }

      return false;
    },
    currentSajuData() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      return currentSection.sectionType === 'personalInfo' ? this.sajuData : this.partnerSajuData;
    },
    formattedCurrentSajuData() {
      const result = [];
      for (let i = 0; i < this.currentSajuData.length; i += 4) {
        result.push(this.currentSajuData.slice(i, i + 4));
      }
      return result;
    },
    sajuTableTitle() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      return currentSection.sectionType === 'personalInfo'
          ? '생년월일시에 따른 사주명식'
          : '상대방의 생년월일시에 따른 사주명식';
    }
  },
  emits: ['close', 'close-later', 'proceed', 'open-popup'],
  methods: {
    goPrev(){
      let sectionType = this.inputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_pre_back_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
      })
      this.$emit('close-later');
    },
    goNext() {
      let sectionType = this.inputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_pre_go_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
      })
      this.$emit('close')
      setTimeout(() => {
        this.$emit('proceed');
      }, 500)
    },
    openPopup() {
      let sectionType = this.inputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_pre_saju_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
      })
      this.$emit('open-popup')
    },
    initPage(){
      this.$nextTick(() => {
        if (!this.inputSections || this.inputSections.length === 0) {
          setTimeout(() => {
            this.initPage();
          }, 500);
        } else {
          let sectionType = this.inputSections[this.currentIndex -1].sectionType
          let convertedSectionType;
          if (sectionType === 'personalInfo'){
            convertedSectionType = 'myinfo';
          } else if (sectionType === 'partnerInfo'){
            convertedSectionType = 'otherinfo';
          } else if (sectionType === 'survey'){
            convertedSectionType = 'survey';
          } else {
            convertedSectionType = 'additional';
          }
          logEvent(`boxinput_${convertedSectionType}_pre_into`, {
            'tarot_box_id': this.tarotBoxData.id,
            'tarot_box_name': this.tarotBoxData.title,
          })
        }
      });
    }
  }
}
</script>

<style scoped>
.check-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--Grayscale-Gray12);
  box-sizing: border-box;
  overflow-y: hidden;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.proceed-button-wrapper{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 500;
}

.proceed-button {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  background: var(--Color-palette-Primary-Default);
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray12);
  color: var(--Color-palette-Primary-Default);
  outline: 2px solid var(--Color-palette-Primary-Default);
  font-size: 16px;
  font-weight: 700;
}

.check-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 54px 24px 70px 24px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.content-wrapper {
  animation: fadeInDown 0.5s ease-out 0.5s both;
  width: 100%;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 12px;
  gap: 8px;
}

.answer {
  flex: 0 0 auto;
  max-width: 100%;
}

.answer-title {
  display: inline-block;
  padding: 8px 16px;
  background: var(--Grayscale-Gray11, #F5F5F5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  color: var(--Grayscale-Gray3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
}

.saju-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 36px;
  gap: 12px;
}

.saju-table-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;

}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.table-header-item{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 7px 0;
  background: var(--Grayscale-Gray3);
  font-size: 14px;
  font-weight: 700;
  color: var(--Grayscale-Gray12);
  box-sizing: border-box;
}

.table-header-item:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-header-item:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #D9D9D9;

}

.table-header-item:nth-child(2) {
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}

.saju-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.saju-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
}

.saju-cell:nth-child(2){
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}

.saju-cell:nth-child(3){
  border-right: 1px solid #D9D9D9;
}

.saju-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: var(--Grayscale-Gray12);
  font-size: 20px;
  font-weight: 700;
  color: var(--Grayscale-Gray3);
  box-sizing: border-box;
  gap: 6px;
}

.saju-hangul{
  font-size: 14px;
  color: var(--Grayscale-Gray5);
}

.saju-info{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  background: var(--Grayscale-Gray11);
  border-radius: 50px;
  gap: 4px;
  box-sizing: border-box;
}

.saju-body.skeleton {
  position: relative;
  overflow: hidden;
}

.saju-body.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-100%);
  animation: skeleton-loading 1.5s infinite;
}

.skeleton-box {
  background: #e0e0e0;
  border-radius: 4px;
}

.saju-hanja.skeleton-box {
  width: 22px;
  height: 22px;
}

.saju-hangul.skeleton-box {
  width: 14px;
  height: 14px;
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

</style>

<template>
  <div class="component-container" v-safe-area-top v-safe-area-bottom :style="{ padding: padding}">
    <div class="content-wrapper">
      <div class="heading-texts">
        <span class="font20 bold gray1">{{ currentPageData.page_title}}</span>
      </div>
      <div class="recom-img">
        <ShimmerImage
            :src="currentPageData.page_content.promotion_img_url"
            :imageStyle="{ width: '67vw' }"
            :heightRatio="71/90"
            :isCircle="false"
            :borderRadius="'8px'"/>      </div>
      <div class="recom-text">
        <div class="bold font18 gray1">{{currentPageData.page_content.title}}</div>
        <div class="font14 gray4" style="word-break: keep-all">{{currentPageData.page_content.sub_title}}</div>
      </div>

      <div class="reviews" v-for="(review, index) in currentPageData.page_content.reviews" :key="index">
        <div class="review-container comment-box">
          <div class="font14 gray4" style="text-align: left; width: 100%">{{review.user_review}}</div>
          <div class="font12 gray7" style="text-align: left">{{ maskedDisplayName(review.display_name) }}</div>
        </div>
      </div>
      <div class="cta button-box" @click="goToTarotBox">보러가기 ></div>
    </div>
  </div>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {mapActions, mapGetters} from "vuex";
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor,
  logEvent,
  showBottomBar
} from "@/tarot_box/helper/native_api";
import {goToMyTarotTab} from "@/common/app_webview_handler";

export default {
  name: "RecommendComponent",
  components: {ShimmerImage},
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  emits: ['open-card-info'],
  data() {
    return {
      imageLoaded: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      logEvent('result_recommend_into', {'recommended_box_name': this.currentPageData.page_content.title });
    });
  },
  computed: {
    ...mapGetters(['user']),
    maskedDisplayName() {
      return (displayName) => {
        if (displayName.length === 2) {
          return displayName.charAt(0) + '*';
        } else if (displayName.length > 2) {
          return displayName.slice(0, 2) + '*'.repeat(displayName.length - 2);
        }
        return displayName;
      };
    },
    padding() {
      return this.isShare ? '48px 24px 80px 24px' : '100px 24px 80px 24px';
    }
  },
  methods: {
    ...mapActions(['setTarotBoxesData']),
    async goToTarotBox() {
      const isShare = this.$route.query.is_share === 'true';
      if (isShare) {
        window.open(`https://my-tarot-web.web.app/tarot_box/home?tarot_box_id=${this.currentPageData.page_content.id}&is_web=true`, '_blank');
      } else {
        //TODO: NEW APP시 딥링크 로직으로 구현
        if(this.user && this.user.phoneNumber && this.user.userId){
          showBottomBar();
          changeBottomSafeAreaColor('#FF171B2B');
          changeTopSafeAreaColor('#FFFFFFFF');

          const buildNumber = this.$route.query.build_number;
          const osType = window.navigator.userAgent.toLowerCase();
          let os = 'aos';
          if (osType.indexOf('iphone') > -1 || osType.indexOf('ipad') > -1) {
            os = 'ios';
          }
          console.log('osType', os);
          console.log(window.location.href);
          const insetTop = new URL(window.location.href).searchParams.get("inset_top");
          const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom");
          this.$router.push(`/tarot_box/home?tarot_user_id=${this.user.userId}&phone_number=${this.user.phoneNumber}&tarot_box_id=${this.currentPageData.page_content.id}&os_type=${os}&build_number=${buildNumber}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
        } else {
          goToMyTarotTab();
        }
      }
      logEvent('result_recommend_go_click', {
        'recommended_box_name': this.currentPageData.page_content.title,
        'recommended_box_id': this.currentPageData.page_content.id,
      });
    }
  }
};
</script>

<style scoped>
.component-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}
.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}
.recom-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
#recom-img {
  width: 30%;
  height: auto;
  border-radius: 4px;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
}
.recom-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 28px 0 28px 0;
}

.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 6px;
}

.review-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: left;
}

.cta{
  width: 100%;
  height: 52px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

}
</style>
<template>
  <div class="review-container">
    <div class="review-title">
      <div class="title2">
        리뷰
        <span style="color: var(--Color-palette-Primary-Default)" v-if="selectedTarotBox.reviewCount > 5">
          {{ selectedTarotBox.reviewCount }}
        </span>
      </div>
      <div class="see-more" @click="openReviewPage(null)">
        <span class="text2"
              style="color: var(--Color-palette-Primary-Default)">
          모두 보기
        </span>
      </div>
    </div>
    <div class="review-description">
      <Flicking
          @changed="handleScroll"
          :options="{
            circular: false,
            align: 'prev',
            horizontal: true,
            moveType: 'strict',
            bound: false,
            threshold: 0,
            duration: 400,
            useFractionalSize: true,
    }">
        <div v-for="(review, index) in filteredReviews" :key="index" class="panel" @click="openReviewPage(review)">
          <ReviewItem :review="review" />
        </div>

      </Flicking>
    </div>
    <div :class="{
      'closing-review-page': closingReviewPage, 'review-page-active': openingReviewPage}">
      <ReviewPage :review="allReviews"
                  :is-active="reviewPageActive"
                  :highlighted-review="highlightedReview"
                  @reset-back-button="$emit('reset-back-button')"
                  :fold-review-page="foldReviewPage"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReviewItem from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailReviews/ReviewItem";
import ReviewPage from "@/tarot_box/pages/home/components/review_page/ReviewPage";
import Flicking from '@egjs/vue3-flicking';
import {getBoxReviewList} from "@/tarot_box/helper/mytarot_api";
import {changeBottomSafeAreaColor, logEvent} from "@/tarot_box/helper/native_api";


export default {
  name: "TarotDetailReviews",
  components: {
    ReviewItem,
    ReviewPage,
    Flicking
  },
  data() {
    return {
      highlightedReview: null,
      reviewPageActive: false,
      openingReviewPage: false,
      closingReviewPage: false,
      reviewCount: 0,
      allReviews:[]
    };
  },
  props: {
    selectedTarotBox: {
      type: Object,
    },
  },
  mounted() {
    this.getReviewList();
  },
  watch: {
  },
  computed: {
    ...mapGetters(["tarotBoxes"]),
    filteredReviews() {
      const filtered = this.selectedTarotBox.userReviews.filter(review => review.user_review.length > 0);
      return filtered.slice(0, 10);
    },
  },
  methods: {
    async getReviewList(){
      let res = await getBoxReviewList(this.selectedTarotBox.id);
      if (res.result === 'success'){
        this.allReviews = res.data;
      } else{
        console.log('리뷰 목록을 가져오는데 실패했습니다.')
      }
    },
    handleScroll() {
      console.log ('review scrolled')
    },
    openReviewPage(review = null) {
      changeBottomSafeAreaColor('#FFFFFF')
      this.openingReviewPage = true;
      this.highlightedReview = review;
      if(review === null){
        logEvent("snack_detail_reviewall_click", {
          "tarot_box_id": this.selectedTarotBox.id,
          "tarot_box_name": this.selectedTarotBox.title}
        );
      } else {
        logEvent("snack_detail_reviewcont_click", {
              "tarot_box_id": this.selectedTarotBox.id,
              "tarot_box_name": this.selectedTarotBox.title
            }
        );
      }
      this.reviewPageActive = true;
      logEvent("snack_review_list_into", {
        "tarot_box_id": this.selectedTarotBox.id,
        "tarot_box_name": this.selectedTarotBox.title
      });
    },
    foldReviewPage() {
      this.openingReviewPage = false;
      this.closingReviewPage = true;
      setTimeout(() => {
        this.reviewPageActive = false;
        setTimeout(() => {
          this.closingReviewPage = false;
        }, 350);
      }, 350);
    },
  },
};
</script>


<style scoped>
.review-container {
  width: calc(100vw - 32px);
  padding: 30px 16px;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  text-align: left;
}

.review-title {
  position: relative;
  margin-bottom: 10px;
}

.see-more{
  position: absolute;
  top: 3px;
  right: 16px;
}

.closing-review-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 100;
  width: 100vw;
  transform: translateX(0%);
  transition: transform 0.35s ease-in;
}

.review-page-active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%; /* 수정된 부분 */
  z-index: 100;
  width: 100vw;
  transition: transform 0.35s ease-out;
  transform: translateX(-100%);
}

</style>
<template>
  <IntersectionObserverComp v-on:triggerFadeIn="fadeIn">
    <transition v-on:enter="enter">
      <div v-show="isShow" ref="thumbnailWrapper">
        <div class="main-recommend">
          <TitleOnlyText :title="sectionData.title"/>
          <div class="thumbnail-area"
               @touchstart.passive="handleTouchStart"
               @touchend="handleTouchEnd">
            <ThumbnailImageM v-for="tarotBox in sectionData.tarotBoxIds" :key="tarotBox"
                             :src="tarotBoxes.find(box => box.id === tarotBox).thumbnailImgUrl"
                             @click="handleCardClick(tarotBox)"/>
          </div>
        </div>
      </div>
    </transition>
  </IntersectionObserverComp>
</template>

<script>

import {defineComponent} from "vue";
import TitleOnlyText from "@/tarot_box/pages/home/components/common/title/TitleOnlyText.vue";
import ThumbnailImageM from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageM.vue";
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import IntersectionObserverComp from "@/common/IntersectionObserverComp.vue"

export default defineComponent({
  name: "RecommendList",
  components: {IntersectionObserverComp, ThumbnailImageM, TitleOnlyText},
  inject: ["eventBus"],
  mounted() {
    setTimeout(() => {
      this.checkIfSwipeNeeded();
    }, 1000);
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      startX: 0,
      endX: 0,
      hasSwiped: false,
      needSwipe: false,
      isShow: false,
    }
  },
  methods: {
    handleCardClick(boxId) {
      this.eventBus.emit('openTarotDetail', boxId);
      const box = this.tarotBoxes.find(box => box.id === boxId);
      const boxTitle = box ? box.title : '';
      logEvent('home_recommend_thumb_click', {"tarot_box_id": boxId, "tarot_box_name": boxTitle});
      logEvent('home_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle,
        "click_origin_name": this.sectionData.title,
        "click_origin": "recommend_list"});
    },
    handleTouchStart(e) {
      if (!this.needSwipe) return;
      this.startX = e.touches[0].clientX;
    },
    handleTouchEnd(e) {
      if (this.needSwipe === false ) return;

      this.endX = e.changedTouches[0].clientX;
      const diff = this.startX - this.endX;

      if (Math.abs(diff) > 30) {
        logEvent('home_recommend_thumb_swipe', {});
        this.hasSwiped = true;
      }
    },
    checkIfSwipeNeeded() {
      const numberOfThumbnails = this.sectionData.tarotBoxIds.length;
      const thumbnailWidth = 126; // 고정된 이미지 너비
      const gap = 10; // 간격

      const totalWidth = (thumbnailWidth + gap) * numberOfThumbnails - gap;
      this.needSwipe = totalWidth > window.innerWidth;

      this.needSwipe = totalWidth > window.innerWidth;
    },
    enter: function (el) {
      el.style.opacity = 0;
    },
    fadeIn: function () {
      this.isShow = true;
      this.$refs.thumbnailWrapper.style = "transition: opacity 1s";
    },
  }
})
</script>

<style scoped>
.main-recommend {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow: visible;

  padding-left: 16px;
}

.thumbnail-area {
  margin-left: -16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  :first-child {
    margin-left: 16px;
  }
  :last-child {
    padding-right: 16px;
  }
}
</style>
<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          추가질문을 건너뛰시겠어요?
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          지금 당장 떠오르는 질문이 없더라도<br>
          이후 해당 페이지에서 추가질문이 가능해요
        </span>
      </div>
      <div class="popup-footer">
        <div class="btn gray" @click="handleCancel">
          취소
        </div>
        <div class="btn" @click="proceedToNext" style="background: var(--Color-palette-Primary-Default)">
          건너뛰기
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProceedPopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    requestSuccessful: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close','next-pages'],
  methods: {
    handleCancel() {
      this.closeConfirmPopupCallback();
    },
    async closeConfirmPopupCallback() {
      this.$emit('close');
    },
    proceedToNext() {
      this.$emit('next-pages');
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 16px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 16px;
  justify-content: space-between;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}

.gray{
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray3);
}
</style>
<template>
  <VueBottomSheet
      v-if="currentIndex !== 0"
      :max-height="sheetHeight"
      :can-swipe="false"
      @opened="$emit('opened')"
      @closed="$emit('closed')"
      ref="bottomSheet">
    <component
        :is="currentComponent"
        :payload="payload"
        :tarot-box-history="tarotBoxHistory"
        :result-page-data="tarotBoxHistory.result_page_data"
        @kakao-share="kakaoShare"
        @link-share="linkShare"
        @close="closeBottomSheet"
        @go-page="goPage"/>
  </VueBottomSheet>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import IndexComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/IndexComponent.vue";
import TarotGuideComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/TarotGuideComponent.vue";
import SajuGuideComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/SajuGuideComponent.vue";
import TarotInfoComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/TarotInfoComponent.vue";
import SipsungInfoComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/SipsungInfoComponent.vue";
import ShareComponent from "@/tarot_box/pages/box_result_v2/components/BottomSheets/ShareComponent.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "BottomSheet",
  components: {
    VueBottomSheet,
    IndexComponent,
    TarotGuideComponent,
    SajuGuideComponent,
    TarotInfoComponent,
    SipsungInfoComponent,
    ShareComponent
  },
  data() {
    return {
      sheetHeight: 400,
      payload: null
    };
  },
  mounted() {
    this.calculateSheetHeight();
    window.addEventListener('resize', this.calculateSheetHeight);
    this.$nextTick(() => {
      if(this.currentComponent === IndexComponent){
        logEvent('result_index_popup', {})
      }else if(this.currentComponent === ShareComponent){
        logEvent('result_UI_share_popup', {})
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateSheetHeight);
  },
  props: {
    currentIndex: {
      type: Number,
    },
    tarotBoxHistory: {
      type: Object,
    },
    type: {
      type: String,
    }
  },
  emits: ['go-page', 'link-share', 'kakao-share', 'close', 'opened', 'closed'],
  computed: {
    currentComponent() {
      switch(this.type) {
        case 'index':
          return IndexComponent;
        case 'tarotGuide':
          return TarotGuideComponent;
        case 'sajuGuide':
          return SajuGuideComponent;
        case 'tarotInfo':
          return TarotInfoComponent;
        case 'sipsungInfo':
          return SipsungInfoComponent;
        case 'share':
          return ShareComponent;
        default:
          return null;
      }
    }
  },
  methods: {
    calculateSheetHeight() {
      if(this.type === 'index'){
      this.sheetHeight = window.innerHeight * 0.6;
      } else if(this.type === 'share') {
        this.sheetHeight = 480;
      } else if(this.type === 'tarotGuide'){
        this.sheetHeight = window.innerWidth / 26 * 38 + 120;
      } else if(this.type === 'sajuGuide'){
        this.sheetHeight = window.innerWidth / 26 * 25 + 120;
      } else {
        this.sheetHeight = 400;
      }
    },
    open(payload) {
      this.payload = payload;
      this.$nextTick(() => {
        this.calculateSheetHeight();
        this.$refs.bottomSheet.open();
      });
    },
    closeBottomSheet() {
      this.$refs.bottomSheet.close();
    },
    goPage(index) {
      logEvent('result_index_popup_list_click', { 'index': index });
      this.$emit("go-page", index);
      this.closeBottomSheet();
    },
    linkShare(){
      this.$emit('link-share');
    },
    kakaoShare() {
      this.$emit('kakao-share');
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>


<style scoped>


</style>
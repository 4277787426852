<template>
  <div class="tag caption2" :style="{background: tagColor || color}">
    {{ tagName }}
  </div>
</template>

<script>
export default {
  name: "CategoryTag",
  props: {
    tagName: {
      type: String,
    },
    tagColor: String
  },
  computed: {
    //Rob: 별도 분리 후 컬러를 수동으로 지정하는 로직을 구현했으나, tagColor로 받아오는 값이 우선적으로 적용됨. 추가 조정 필요
    color() {
      switch (this.tagName) {
        case '사주':
          return '#A15B08';
        case '타로':
          return '#9C4CC1';
        case '관상':
          return '#20AC9C';
        case '2024운세':
          return '#1E2352';
        case '무료운세':
          return '#C7983D';
        case '오늘운세':
          return '#F4742D';
        case '솔로탈출':
          return '#EF608B';
        case '재회':
          return '#6B94FF';
        case '오늘할인':
          return '#F4742D';
        default:
          return '#000000';
      }
    }
  },
  data() {
    return {};
    }
};

</script>

<style scoped>
.tag {
  display: flex;
  width: 44px;
  padding: 3px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  flex-shrink: 0;

  color: var(--Grayscale-Gray12)
}
</style>
<template>
  <div v-safe-area-top class="header-bar" :style="{ backgroundColor: bgColor }" @click="clickCountForDevMode++">
    <img id="logo" src="../../assets/images/home-logo.png" alt="logo">
    <img id="search" src="" alt="search">
    <div v-if="!fromEvent" v-web class="app-download-button" style="transform: translateY(-2px)" @click="goAppStore">
      <svg width="74" height="26" viewBox="0 0 74 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="72" height="24" rx="12" fill="white"/>
        <rect x="1" y="1" width="72" height="24" rx="12" stroke="black" stroke-width="0.5"/>
        <path d="M12.3365 7.928C13.7405 7.928 14.7365 8.912 14.7365 10.328C14.7365 11.732 13.7405 12.716 12.3365 12.716C10.9325 12.716 9.93648 11.732 9.93648 10.328C9.93648 8.912 10.9325 7.928 12.3365 7.928ZM10.8845 10.328C10.8845 11.24 11.4965 11.864 12.3365 11.864C13.1765 11.864 13.8005 11.24 13.8005 10.328C13.8005 9.404 13.1765 8.78 12.3365 8.78C11.4965 8.78 10.8845 9.404 10.8845 10.328ZM11.7965 17.768V13.568H12.8285V14.78H17.7485V13.568H18.7565V17.768H11.7965ZM12.8285 16.916H17.7485V15.608H12.8285V16.916ZM15.5045 13.028V7.544H16.4765V9.74H17.7605V7.352H18.7565V13.1H17.7605V10.592H16.4765V13.028H15.5045ZM28.3208 7.34H29.3168V17.9H28.3208V7.34ZM20.4608 11.9C20.4608 9.62 21.3488 8.204 22.7048 8.204C23.9888 8.204 24.8408 9.428 24.9608 11.42H26.2208V7.568H27.2048V17.372H26.2208V12.272H24.9608C24.8768 14.348 24.0128 15.608 22.7048 15.608C21.3488 15.608 20.4608 14.192 20.4608 11.9ZM21.4088 11.9C21.4088 13.604 21.9128 14.636 22.7048 14.636C23.5208 14.636 24.0128 13.604 24.0128 11.9C24.0128 10.196 23.5208 9.176 22.7048 9.176C21.9128 9.176 21.4088 10.196 21.4088 11.9ZM36.097 10.892H38.497V7.328H39.529V17.924H38.497V11.744H36.097V10.892ZM30.793 14.852C32.365 14.084 33.517 12.044 33.517 10.088V8.24H34.549V10.088C34.549 12.008 35.677 13.94 37.237 14.672L36.625 15.512C35.437 14.9 34.513 13.676 34.045 12.212C33.577 13.76 32.629 15.056 31.441 15.692L30.793 14.852ZM44.083 15.704H48.331V13.484H45.199V8.072H46.231V9.92H51.487V8.072H52.519V13.484H49.363V15.704H53.647V16.568H44.083V15.704ZM46.231 12.644H51.487V10.76H46.231V12.644ZM62.2032 7.34H63.2472V17.9H62.2032V7.34ZM54.6792 15.128C57.4872 13.784 58.8672 11.804 59.0712 9.32H55.1712V8.468H60.1272C60.1272 11.552 58.7952 14.228 55.2312 15.956L54.6792 15.128Z" fill="#121212"/>
      </svg>
    </div>
    <div v-web v-else class="app-download-button" style="transform: translateY(-2px)" @click="backToEventPage">
      <svg width="95" height="26" viewBox="0 0 95 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="93" height="24" rx="12" fill="white"/>
        <rect x="1" y="1" width="93" height="24" rx="12" stroke="black" stroke-width="0.5"/>
        <path d="M17.4362 7.316H18.5282V17.936H17.4362V7.316ZM10.2122 11.84C10.2122 9.56 11.3402 8.132 12.9242 8.132C14.4962 8.132 15.6362 9.56 15.6362 11.84C15.6362 14.108 14.4962 15.56 12.9242 15.56C11.3402 15.56 10.2122 14.108 10.2122 11.84ZM11.2682 11.84C11.2682 13.52 11.9522 14.576 12.9242 14.576C13.8962 14.576 14.5802 13.52 14.5802 11.84C14.5802 10.16 13.8962 9.116 12.9242 9.116C11.9522 9.116 11.2682 10.16 11.2682 11.84ZM28.2365 7.34H29.2925V15.356H28.2365V7.34ZM20.7965 13.388V8.168H21.8165V9.908H23.8805V8.168H24.9125V10.364H26.1965V7.532H27.2405V15.044H26.1965V11.252H24.9125V13.388H20.7965ZM21.8165 12.512H23.8805V10.772H21.8165V12.512ZM22.3325 17.696V14.276H23.4245V16.796H29.5925V17.696H22.3325ZM32.0408 8.204H39.2408V9.092H33.1448V10.556H39.0128V11.432H33.1448V12.968H39.3488V13.856H32.0408V8.204ZM30.8288 16.58V15.68H40.4168V16.58H30.8288ZM45.3427 7.736H52.4107V8.612H46.4347V10.064H52.5187V10.94H49.4107V11.864H53.6467V12.74H44.0827V11.864H48.3187V10.94H45.3427V7.736ZM45.2227 14.372V13.544H52.4347V16.004H46.3267V16.964H52.7467V17.816H45.2587V15.224H51.3547V14.372H45.2227ZM57.391 8.132C58.963 8.132 60.079 9.56 60.079 11.84C60.079 14.108 58.963 15.56 57.391 15.56C55.831 15.56 54.703 14.108 54.703 11.84C54.703 9.56 55.831 8.132 57.391 8.132ZM55.759 11.84C55.759 13.52 56.419 14.576 57.391 14.576C58.363 14.576 59.035 13.52 59.035 11.84C59.035 10.16 58.363 9.116 57.391 9.116C56.419 9.116 55.759 10.16 55.759 11.84ZM61.651 17.924V7.34H62.755V11.516H64.435V12.428H62.755V17.924H61.651ZM72.1272 7.316H73.2432V11.564H74.8752V12.476H73.2432V17.912H72.1272V7.316ZM65.0832 15.104C67.7232 13.76 69.1632 11.828 69.3792 9.344H65.5992V8.456H70.4832C70.4832 11.504 69.1392 14.216 65.6952 15.944L65.0832 15.104ZM83.1555 7.328H84.2595V17.912H83.1555V7.328ZM75.6435 15.116C78.4515 13.76 79.8075 11.828 80.0235 9.344H76.1475V8.456H81.1275C81.1275 11.564 79.8195 14.24 76.2195 15.98L75.6435 15.116Z" fill="#121212"/>
      </svg>
    </div>
  </div>
</template>



<script>
// import {hideBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "MainHeaderBar",
  props: {
    bgColor:{
      type: String,
      default: '#FFFFFF'
    }
  },
  data() {
    return {
      clickCountForDevMode: 0,
      fromEvent: false,
    }
  },
  mounted() {
    const fromEvent = new URLSearchParams(window.location.search).get('from_event');
    this.fromEvent = fromEvent === 'true';
  },
  watch: {
    clickCountForDevMode() {
      if (this.clickCountForDevMode === 20) {
        console.log('clickCountForDevMode', this.clickCountForDevMode)
        const tarot_user_id = this.$route.query.tarot_user_id;
        const phone_number = this.$route.query.phone_number;
        const build_number = this.$route.query.build_number;
        const inset_top = this.$route.query.inset_top;
        const inset_bottom = this.$route.query.inset_bottom;
        const os_type = new URLSearchParams(window.location.search).get('os_type');

        let targetPath;
        if (this.$route.path === '/tarot_box/home') {
          targetPath = '/tarot_box/mytarot';
        } else if (this.$route.path === '/tarot_box/mytarot') {
          targetPath = '/tarot_box/home';
        } else {
          // If on neither page, don't do anything
          return;
        }

        const baseUrl = window.location.hostname === 'my-tarot-vue-stage.web.app'
            ? 'https://my-tarot-vue-stage.web.app'
            : 'https://my-tarot-vue-stage.web.app'; // You might want to change this for production

        const url = `${baseUrl}${targetPath}?tarot_user_id=${tarot_user_id}&phone_number=${phone_number}&os_type=${os_type}&build_number=${build_number}&inset_top=${inset_top}&inset_bottom=${inset_bottom}`;

        window.location.href = url;
      }
    }
  },
  methods: {
    goAppStore() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent)
      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://apps.apple.com/kr/app/id6446103793?1z_external_browser=true';
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      }
    },
    backToEventPage() {
      const is_web = new URLSearchParams(window.location.search).get('is_web');
      const utm_content = new URLSearchParams(window.location.search).get('utm_content');
      this.$router.push('/tarot_box/event?scroll_position=0.38&is_web=' + is_web + '&utm_content=' + utm_content);
    }
  }
}
</script>


<style scoped>
.header-bar{
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  border-bottom: 0.5px solid #E5E5E5;
}

#logo{
  margin-left: 19px;
  height: 44px;
  width: auto;
}

#search{
  display: none;
  height: 44px;
  width: auto;
  margin-right: 22px;
}

.app-download-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  right: 24px;
}
</style>

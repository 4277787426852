<template>
  <div class="desc-container">
    <div class="desc-title">
      <div class="title2">미리보기</div>
    </div>
    <div class="carousel-wrapper">
      <Flicking
          @changed="swipeImagePopup"
          :options="{
              circular: false,
              bounce: '20%',
              align: 'prev',
              moveType: 'snap',
              bound: true
               }">
        <div v-for="(slide, index) in selectedTarotBox?.screenShotImgUrlList" :key="index" class="carousel_item" @click="openImagePopup(index, slide)">
          <ShimmerImage
              :src="slide"
              :imageStyle="{ width: '180px' }"
              :heightRatio="1.66"
              :isCircle="false"
              :borderRadius="'8px'"/>
        </div>
      </Flicking>
    </div>
    <ScreenShotPopup
        :isActive="imagePopupActive"
        :closePopupCallback="closePopupCallback"
        :numberOfCarousel="numberOfCarousel"
        :currentSlideIndex="currentSlideIndex"
        :selectedTarotBox="selectedTarotBox"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import Flicking from '@egjs/vue3-flicking';
import ScreenShotPopup from "@/tarot_box/pages/home/components/tarot_box_detail/components/ScreenshotPopup";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "TarotDetailTableOfContents",
  components: {
    ShimmerImage,
    ScreenShotPopup,
    Flicking
  },
  data() {
    return {
      numberOfCarousel: 0,
      selectedImage: '',
      imagePopupActive: false,
      currentSlideIndex: 0,
    };
  },
  props: {
    selectedTarotBox: {
      type: Object,
    },
    imageLoaded: {
      type: Boolean,
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["tarotBoxes"]),
  },
  methods: {
    openImagePopup(index, slide){
      this.currentSlideIndex = index;
      this.selectedImage = slide;
      this.imagePopupActive = true;
      logEvent("snack_detail_shot_click", {tarot_box_id: this.selectedTarotBox.id});
    },
    swipeImagePopup(){
      if(this.imageLoaded === true){
      logEvent('snack_detail_shot_swipe', {tarot_box_id: this.selectedTarotBox.id});
      }
    },
    closePopupCallback(){
      this.imagePopupActive = false;
    },
  }
};
</script>


<style scoped>
.desc-container {
  width: 100%;
  padding: 30px 16px;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  text-align: left;
}
.carousel-wrapper{
  width: 100%;
  overflow-x: hidden;
}
.carousel_item {
  margin: 12px 4px 0 4px;
  border: 0.5px solid var(--Grayscale-Gray9);
  border-radius: 8px;
}

.carousel_item:last-child {
  margin-right: 50px;
}
</style>
<template>
<div class="title-area">
  <div class="title2 ellipsis">
    {{title}}
  </div>
</div>
</template>

<script>
export default {
  name: "TitleOnlyText",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
.title-area{
  display: flex;
  width: auto;
  height: 48px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;

  color: var(--Grayscale-Gray1);
}
</style>
<template>
  <div class="title-container">
    <div class="image-cover">
      <img id="cover"
           :src="selectedTarotBox?.thumbnailImgUrl"
           @load="onImageLoaded($event)"
           alt="tarot-box-cover">
    </div>
    <div class="image-area">
      <img id="thumbnail"
           :src="selectedTarotBox?.thumbnailImgUrl"
           @load="onImageLoaded($event)"
           alt="tarot-box-thumbnail">
      <div class="image-tags">
        <CategoryTag
            v-for="(tag, index) in parsedTags"
            :key="tag"
            :tagName="tag"
            :class="{ 'first-tag': index === 0 }"
        />
        <CategoryTag
            v-for="(title, index) in parsedSubjectTitles"
            :key="index"
            :tagName="title"
            :tagColor="selectedTarotBox?.subjectColor"
        />
      </div>
    </div>
    <div class="title-area">
      <div class="title1" v-html="parseText(selectedTarotBox?.title)"/>
      <div class="text2" v-html="parseText(selectedTarotBox?.subTitle)"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CategoryTag from "@/tarot_box/pages/home/components/main_list_types/CategoryTag.vue";

export default {
  name: "TarotDetailTitleArea",
  components: {
    CategoryTag,
  },
  data() {
    return {
      isCoverLoaded: false,
      isThumbnailLoaded: false,
      isImagesLoaded: false,    }
  },
  props: {
    selectedTarotBox: {
      type: Object,
    },
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
    parsedTags() {
      return this.selectedTarotBox?.category ? this.selectedTarotBox.category.split(',') : [];
    },
    parsedSubjectTitles() {
      return this.selectedTarotBox?.subjectTitle ? this.selectedTarotBox.subjectTitle.split(',') : [];
    }
  },
  methods: {
    parseText(text) {
      if (text) {
        return text.replace(/\\n/g, '<br>');
      } else {
        return '';
      }
    },
    onImageLoaded(event) {
      const imageId = event.target.id;

      if (imageId === 'cover') {
        this.isCoverLoaded = true;
      } else if (imageId === 'thumbnail') {
        this.isThumbnailLoaded = true;
      }

      if (this.isCoverLoaded && this.isThumbnailLoaded) {
        this.isImagesLoaded = true;
        this.$emit('image-loaded');
        this.isCoverLoaded = false;
        this.isThumbnailLoaded = false;
      }
    },
  }
}
</script>


<style scoped>
.title-container{
  position: relative;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  padding-bottom: 30px;
}
.image-cover {
  position: absolute;
  top: 0;
  left:0;
  width: calc(100vw + 10px);
  max-width: 1024px;
  height: 136px;
  overflow: hidden;
  z-index: -1;
  margin: -5px; /* 또는 필요에 따라 조정 */
}

#cover {
  width: calc(100% + 10px); /* 컨테이너 여백만큼 추가 */
  height: calc(100% + 10px); /* 컨테이너 여백만큼 추가 */
  object-fit: cover;
  object-position: center;
  filter: brightness(0.5) blur(10px);
  margin: -5px; /* 또는 필요에 따라 조정 */
}

.image-area{
  display: flex;
  height: 165px;
  width: 100vw;
  max-width: 1024px;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 16px;
}

#thumbnail{
  width: 105px;
  height: 147px;
  border-radius: 4px;
  border: 0.2px solid #777;
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 6px;
}

.image-tags{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.image-tags .tag {
  margin-left: 6px;
  margin-right: 6px;
}

.image-tags .tag.first-tag {
  margin-left: 12px;
}

.title-area{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 16px;
  margin-top: 18px
}

.title1{
  max-width: calc(100vw - 32px);
  word-break: keep-all;
  text-align: left;
}

.text2{
  max-width: calc(100vw - 32px);
  word-break: keep-all;
  text-align: left;
  margin-top: 8px;
}
</style>
<template>
  <div class="modal" v-if="isActive">
    <div class="overlay" @click="closeNeedPopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          클로버가 부족해요.<br>
          클로버 구매화면으로 이동할까요?
        </span>
      </div>
      <div class="popup-footer">
        <div class="btn2" @click="closeNeedPopup">
          취소
        </div>
        <div class="btn2" @click="goCloverPaywall" style="flex: 1; background: var(--Color-palette-Primary-Default); color: var(--Grayscale-Gray12)">
          이동하기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WithdrawalPopup",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    }
  },
  emits: ['go-clover-paywall', 'close-need-popup'],
  data() {
    return {
    }
  },
  mounted() {
    window.onBackButtonClick = this.handleBackButton;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  methods: {
    goCloverPaywall() {
      this.$emit('go-clover-paywall');
      this.$emit('close-need-popup');
      this.closePopup();
    },
    closeNeedPopup() {
      this.$emit('close-need-popup');
    },
  }
}
</script>

<style scoped>


.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 10px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.btn2{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>
<template>
  <div class="image-container">
    <div v-if="!imageLoaded" class="loader" :style="loaderStyle">
      <div class="loader-dot"></div>
    </div>
    <img :src="src" alt="shimmer-image" v-show="imageLoaded" @load="handleImageLoad" :style="localImageStyle"/>
  </div>
</template>

<script>
export default {
  name: "ShimmerIcon",
  props: {
    src: {
      type: String,
      required: true,
    },
    imageStyle: {
      type: Object,
      default: () => ({}),
    },
    heightRatio: {
      type: Number,
      default: 1,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '0px',
    },
  },
  data() {
    return {
      imageLoaded: false,
    }
  },
  mounted() {
  },
  computed: {
    loaderStyle() {
      // 로더 크기를 이미지 스타일로부터 가져옵니다.
      const loaderSize = this.imageStyle.width || '48px'; // 기본값 설정
      return {
        '--loader-size': loaderSize
      };
    },
    localImageStyle() {
      let style = { ...this.imageStyle };

      if (this.borderRadius) {
        style.borderRadius = this.borderRadius;
      }
      if (style.width) {
        // 로더 크기를 이미지 너비와 동일하게 설정
        style.loaderSize = style.width;
        const width = parseInt(style.width);
        const unit = style.width.match(/\D+$/)[0]; // 단위 추출
        const height = width * this.heightRatio;

        // 계산된 높이와 최대 너비/높이 설정
        style.height = `${height}${unit}`;
        style.maxWidth = width * 4.8 + unit;
        style.maxHeight = width * 4.8 * this.heightRatio + unit;
      }

      if (style.width) {
        style.loaderSize = style.width;
      }

      return style;
    }
  },
  methods: {
    handleImageLoad(){
      this.imageLoaded = true;
    }
  },
}
</script>


<style scoped>
.loader {
  position: relative;
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 100%;
  box-shadow: inset -2px 0 0 0px var(--Color-palette-Primary-Lighten2);
  animation: spin 1.5s infinite linear;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.image-container {
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
</style>
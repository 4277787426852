<!-- TimeInput.vue -->
<template>
  <div class="time-input" v-safe-area-bottom>
    <div class="time-toggle">
      <span class="title4">태어난 시각 모름</span>
      <div class="alarm-toggle-button">
        <label class="switch">
          <input type="checkbox" v-model="iDunno" @change="handleDunnoChange">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="time-select" :class="{ 'disabled': iDunno }">
      <div class="time-focus">
        <div class="title1" :class="{ 'gray-text': iDunno }" style="flex:1; transform:translateX(65%)">시</div>
        <div class="title1" :class="{ 'gray-text': iDunno }" style="flex:1; transform:translateX(65%)">분</div>
      </div>

      <!-- Hour Picker -->
      <Swiper
          :key="hourSwiperKey"
          ref="hourSwiper"
          :slides-per-view="5"
          :centered-slides="true"
          :space-between="0"
          direction="vertical"
          :initialSlide="hourIndex"
          loop
          class="hour-swiper"
          @swiper="onHourSwiper"
          @slideChange="onHourChange"
          :allowTouchMove="!iDunno"
      >
        <SwiperSlide v-for="hour in hours" :key="hour">
          <div class="swiper-item" :class="{ 'gray-text': iDunno }">{{ hour }}</div>
        </SwiperSlide>
      </Swiper>

      <!-- Minute Picker -->
      <Swiper
          :key="minuteSwiperKey"
          ref="minuteSwiper"
          :slides-per-view="5"
          :centered-slides="true"
          :space-between="0"
          direction="vertical"
          :initialSlide="minuteIndex"
          loop
          class="minute-swiper"
          @swiper="onMinuteSwiper"
          @slideChange="onMinuteChange"
          :allowTouchMove="!iDunno"
      >
        <SwiperSlide v-for="minute in minutes" :key="minute">
          <div class="swiper-item" :class="{ 'gray-text': iDunno }">{{ minute }}</div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="confirm-button buttonL" @click="confirmSelectedTime">
      완료
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import debounce from 'lodash/debounce'
// import { debounce } from 'lodash';

// 필요한 Swiper 모듈만 사용 설정
SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: 'TimeInput',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    title: String,
    subTitle: String,
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedTime: '',
      time: {
        hours: '07',
        minutes: '00',
      },
      hours: Array.from({ length: 24 }, (_, i) => (i).toString().padStart(2, '0')),
      minutes: Array.from({ length: 60 }, (_, i) => (i).toString().padStart(2, '0')),
      iDunno: false,
      debouncedUpdateSwiperSlides: () => {},
      hourSwiperInstance: null,
      minuteSwiperInstance: null,
      // 고유 키를 통해 컴포넌트 리셋 강제화
      hourSwiperKey: 0,
      minuteSwiperKey: 0,
    };
  },
  computed: {
    hourIndex() {
      const index = this.hours.indexOf(this.time.hours);
      console.log('Computed hourIndex:', index); // 디버깅용 로그
      return index !== -1 ? index : 0;
    },
    minuteIndex() {
      const index = this.minutes.indexOf(this.time.minutes);
      console.log('Computed minuteIndex:', index); // 디버깅용 로그
      return index !== -1 ? index : 0;
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        console.log('modelValue changed:', newValue); // 디버깅용 로그
        if (typeof newValue === 'string' && newValue.trim() !== '') {
          console.log('1'); // 디버깅용 로그
          this.initializeTime(newValue);
        } else {
          console.log('2'); // 디버깅용 로그
          this.resetTime();
        }
      }
    }
  },
  created() {
    this.debouncedUpdateSwiperSlides = debounce(this.updateSwiperSlides, 100);
  },
  mounted() {
    // Swiper 슬라이드를 초기화
    this.$nextTick(() => {
      this.debouncedUpdateSwiperSlides();
    });
  },
  methods: {
    initializeTime(value) {
      let hours, minutes;
      if (value.includes(':')) {
        [hours, minutes] = value.split(':');
      } else if (value.includes('시') && value.includes('분')) {
        const timeParts = value.split(/시|분/).filter(part => part.trim() !== '');
        [hours, minutes] = timeParts;
      }

      if (hours !== undefined && minutes !== undefined) {
        this.time.hours = hours.trim().padStart(2, '0');
        this.time.minutes = minutes.trim().padStart(2, '0');
        this.updateSelectedTime();
        this.debouncedUpdateSwiperSlides();
      }
    },
    resetTime() {
      this.time.hours = '07';
      this.time.minutes = '00';
      console.log('Resetting time to 07:00'); // 디버깅용 로그
      this.updateSelectedTime();
      this.resetSwiperInstances();
    },
    resetSwiperInstances() {
      // Swiper 인스턴스를 재설정하기 위해 키를 증가시켜 컴포넌트를 재렌더링
      this.hourSwiperKey += 1;
      this.minuteSwiperKey += 1;
    },
    onHourSwiper(swiper) {
      this.hourSwiperInstance = swiper;
      console.log('Hour Swiper initialized:', swiper);
      // loop가 활성화된 상태에서 slideToLoop 사용
      swiper.slideToLoop(this.hourIndex, 0);
    },
    onMinuteSwiper(swiper) {
      this.minuteSwiperInstance = swiper;
      console.log('Minute Swiper initialized:', swiper);
      // loop가 활성화된 상태에서 slideToLoop 사용
      swiper.slideToLoop(this.minuteIndex, 0);
    },
    updateSwiperSlides() {
      if (this.hourSwiperInstance && this.minuteSwiperInstance) {
        this.hourSwiperInstance.slideToLoop(this.hourIndex, 0);
        this.minuteSwiperInstance.slideToLoop(this.minuteIndex, 0);
      } else {
        // Swiper이 아직 초기화되지 않았다면, 짧은 지연 후 다시 시도
        setTimeout(() => this.debouncedUpdateSwiperSlides(), 50);
      }
    },
    onHourChange() {
      if (!this.iDunno && this.hourSwiperInstance) {
        const realIndex = this.hourSwiperInstance.realIndex;
        console.log('Hour Swiper realIndex:', realIndex);
        this.time.hours = this.hours[realIndex];
        this.updateSelectedTime();
      }
    },
    onMinuteChange() {
      if (!this.iDunno && this.minuteSwiperInstance) {
        const realIndex = this.minuteSwiperInstance.realIndex;
        console.log('Minute Swiper realIndex:', realIndex);
        this.time.minutes = this.minutes[realIndex];
        this.updateSelectedTime();
      }
    },
    updateSelectedTime() {
      this.selectedTime = this.iDunno ? '태어난 시각 모름' : `${this.time.hours}시 ${this.time.minutes}분`;
    },
    confirmSelectedTime() {
      this.$emit('select', {
        value: this.selectedTime,
        inputFormType: 'timeInput'
      });
      console.log('Confirmed time:', this.selectedTime);
    },
    handleDunnoChange() {
      if (this.iDunno) {
        this.resetTime();
      } else {
        // Swiper 인스턴스들의 터치 이벤트 재활성화
        if (this.hourSwiperInstance) {
          this.hourSwiperInstance.allowTouchMove = true;
          this.hourSwiperInstance.updateSize();
          this.hourSwiperInstance.updateSlides();
        }
        if (this.minuteSwiperInstance) {
          this.minuteSwiperInstance.allowTouchMove = true;
          this.minuteSwiperInstance.updateSize();
          this.minuteSwiperInstance.updateSlides();
        }
      }
      this.updateSelectedTime();
      this.debouncedUpdateSwiperSlides();
    },
  },
  emits: ['select']
}
</script>

<style scoped>
.time-input {
  margin-top: 80px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "SUIT Variable", sans-serif;
}

.time-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 13px 24px;
  box-sizing: border-box;
  background: var(--Grayscale-Gray11);
  border: 1px 0 1px 0 solid var(--Grayscale-Gray10);
}

.time-select {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  font-family: "SUIT Variable", sans-serif;
}

.time-focus {
  position: absolute;
  width: 100%;
  height: 44px;
  background: var(--Grayscale-Gray11);
  border-radius: 8px;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.hour-swiper, .minute-swiper {
  flex: 2;
  width: 60px;
  height: 180px;
  text-align: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: var(--Grayscale-Gray8);
}

.swiper-slide-active {
  font-weight: bold;
  color: var(--Grayscale-Gray2);
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SUIT Variable", sans-serif;
}

.time-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.gray-text {
  color: var(--Grayscale-Gray8) !important;
}

/* 토글 버튼 스타일 */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--Color-palette-Primary-Default);
}

input:checked + .slider:before {
  transform: translateX(21px);
}

.confirm-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  box-sizing: border-box;
  background: var(--Color-palette-Primary-Default);
  border-radius: 8px;
  color: var(--Grayscale-Gray12);
}
</style>

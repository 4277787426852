<template>
  <div class="category-card-item">
    <div class="thumbnail-area">
      <ThumbnailImageDynamic :src="tarotBoxes.find(box => box.id === boxId).thumbnailImgUrl"
                             :image-width="thumbnailImageWidth" border-radius="4px"/>
    </div>
    <div class="text-area">
      <div class="title5">
        {{newLine(tarotBoxes.find(box => box.id === boxId).title)}}
      </div>
      <div class="caption3">
        {{newLine(tarotBoxes.find(box => box.id === boxId).subTitle)}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ThumbnailImageDynamic from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageDynamic.vue";
import {newLine} from "@/common/utils";

export default {
  name: "CategoryCardItem",
  methods: {newLine},
  components: {
    ThumbnailImageDynamic,
  },
  props: {
    boxId: {
      type: Number
    }
  },
  data() {
    const imgWidth = (window.innerWidth - (16*2) - (12*2))/3;
    return {
      itemTitle : "",
      itemDesc : "",
      thumbnailImageWidth: imgWidth + 'px',
    };
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
  }
}
</script>

<style scoped>
.category-card-item {
  width: calc((100vw - 32px - 24px)/3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.text-area {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
  overflow: hidden;

}

.title5{
  width: calc((100vw - 32px - 24px)/3);

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.caption3 {
  width: calc((100vw - 32px - 24px)/3);

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>
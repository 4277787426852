<template>
  <div class="tarot-card-info-tag-area">
    <div class="tag tag-button title5" @click="shuffleCards()">
      카드 섞기
    </div>

    <div class="tag tag-info text2">
      {{ shortDescription }}
    </div>
  </div>
</template>

<script>
export default {
  inject: ['eventBus'],
  props: {
    shortDescription: {
      type: String,
      required: true
    }
  },
  methods: {
    shuffleCards() {
      this.eventBus.emit('shuffleTarotCards');
    }
  }
}
</script>

<style scoped>
.tarot-card-info-tag-area{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  gap: 6px;
}

.tag {
  padding: 12px 16px;
  border-radius: 90px;
  color: var(--Grayscale-Gray9);
  font-size: 14px;
  line-height: 100%; /* 14px */
}

.tag-button {
  background: #7579A5;
  font-weight: 700;

}

.tag-info {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0.5px solid #3C3A46;
  background: rgba(53, 55, 80, 0.70);
  font-weight: 500;
}
</style>
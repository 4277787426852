<template>
  <div class="review-item">
    <div class="review-contents">
      <span class="text2"
            style="color: var(--Grayscale-Gray4, #555);">
        {{review.user_review}}
      </span>
    </div>

    <div class="reviewer">
      <span class="text2"
            style="color: var(--Grayscale-Gray6, #999);">
        {{ maskedDisplayName }}
      </span>
    </div>

  </div>
</template>

<script>
export default {
  name: "ReviewItem",
  props: {
    review: Object
  },
  computed: {
    maskedDisplayName() {
      if (this.review.display_name) {
        if (this.review.display_name.length <= 2) {
          // 두 글자 이하인 경우 마지막 글자만 마스킹
          return this.review.display_name.slice(0, -1) + '*';
        } else {
          // 두 글자 초과인 경우 앞의 두 글자만 남기고 나머지 마스킹
          const visiblePart = this.review.display_name.substring(0, 2);
          const maskedPart = '*'.repeat(this.review.display_name.length - 2);
          return visiblePart + maskedPart;
        }
      }
      return '';
    },
  }
}
</script>

<style scoped>
.review-item {
  display: grid;
  gap: 8px; /* 영역 사이의 간격 */
  align-items: center;
  padding: 16px;
  width: 75vw;
  max-width: 350px;
  height: 80px;
  border-radius: 8px;
  border: 0.5px solid var(--Grayscale-Gray9, #D9D9D9);
  background: var(--Grayscale-Gray12, #FFF);
  margin-right: 8px;
}

.review-contents{
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 최대 세 줄까지 표시 */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
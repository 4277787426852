<template>
  <IntersectionObserverComp v-on:triggerFadeIn="fadeIn">
    <transition v-on:enter="enter">
      <div v-show="isShow" ref="thumbnailWrapper">
        <div class="tag-box-list">
          <TitleOnlyText :title="title"/>
          <div v-for="tarotBoxId in tarotBoxIds" :key="tarotBoxId"
               class="list-item text2"
               @click="handleCardClick(tarotBoxId)">
            <CategoryTag :tag-name="getFirstSubjectTitle(tarotBoxId)" :tag-color="tarotBoxes.find(box => box.id === tarotBoxId).subjectColor"/>
            <div class="ellipsis">
              {{ tarotBoxes.find(box => box.id === tarotBoxId).title.replace('\\n',' ') }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </IntersectionObserverComp>
</template>

<script>
import TitleOnlyText from "@/tarot_box/pages/home/components/common/title/TitleOnlyText.vue";
import CategoryTag from "@/tarot_box/pages/home/components/main_list_types/CategoryTag.vue";
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import IntersectionObserverComp from "@/common/IntersectionObserverComp.vue"

export default {
  name: "TagBoxList",
  components: {
    IntersectionObserverComp,
    CategoryTag,
    TitleOnlyText,
  },
  inject: ["eventBus"],
  computed: {
    ...mapGetters(['tarotBoxes']),

  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tarotBoxIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    handleCardClick(tarotBoxId) {
      this.eventBus.emit('openTarotDetail', tarotBoxId);
      const box = this.tarotBoxes.find(box => box.id === tarotBoxId);
      const boxTitle = box ? box.title : '';
      logEvent('home_contents_click', {
        "tarot_box_id": tarotBoxId,
        "tarot_box_name": boxTitle,
        "click_origin_name": this.title,
        "click_origin": "home_section_list"});
    },
    getFirstSubjectTitle(tarotBoxId) {
      const box = this.tarotBoxes.find(box => box.id === tarotBoxId);
      if (box && box.subjectTitle) {
        return box.subjectTitle.split(',')[0].trim();
      }
      return '';
    },
    enter: function (el) {
      el.style.opacity = 0;
    },
    fadeIn: function () {
      this.isShow = true;
      this.$refs.thumbnailWrapper.style = "transition: opacity 1s";
    },
  },
};
</script>

<style scoped>
.tag-box-list {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  padding: 0 16px;
}

.list-item {
  display: flex;
  width: auto;
  height: 34px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  gap: 12px;

}
</style>
<template>
  <div class="review-page-wrapper" v-safe-area-top v-if="isActive">
    <div class="detail-header-bar">
        <h2 class="title2" style="color:var(--Grayscale-Gray1); margin-bottom:0;">리뷰</h2>
        <div class="back-button2" @click="foldPage">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 20L8 12L16 4" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    <div class="review-page-container">
      <ReviewPageItem v-for="item in sortedReviews"
                      :key="item.id"
                      :review="item"
                      :is-highlighted="highlightedReview && item.id === highlightedReview.id"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReviewPageItem from "@/tarot_box/pages/home/components/review_page/ReviewPageItem.vue";
import {changeBottomSafeAreaColor} from "@/tarot_box/helper/native_api";

export default {
  name: "ReviewPage",
  components: {ReviewPageItem},
  props: {
    isActive: {
      type: Boolean
    },
    foldReviewPage: {
      type: Function
    },
    review: {
      type: Array
    },
    highlightedReview: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['tarotBoxes', 'homeSections']),
    sortedReviews() {
      if (this.highlightedReview === null) {
        return this.review;
      }
      return [this.highlightedReview, ...this.review.filter(r => r.id !== this.highlightedReview.id)];
    }
  },
  data() {
    return {
    };
  },
  watch: {
    isActive: function (newVal) {
      if (newVal) {
        window.onBackButtonClick = this.foldReviewPage;
      }
    }
  },
  mounted() {
    this.addSafeAreaColorTop('--Grayscale-Gray12');
    this.addSafeAreaColorBottom('--Color-palette-Primary-Default');
  },
  beforeUnmount() {
    this.previousSafeAreaColorBottom();
    this.previousSafeAreaColorTop();
    window.onBackButtonClick = null;
    this.$emit('reset-back-button')
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    foldPage() {
      this.foldReviewPage();
      changeBottomSafeAreaColor('#1E2352');
    },
  },
}
</script>

<style scoped>
.review-page-wrapper {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--Grayscale-Gray12);
  display: flex;
  flex-direction: column;
}
.detail-header-bar{
  min-height: 68px;
  height: 68px;
  width: 100vw;
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;
  background: var(--Grayscale-Gray12);
  border-bottom: 1px solid var(--Grayscale-Gray10, #ECECEC);
  position: fixed;
  z-index: 9999;
}

.review-page-container{
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 88px;
  padding-bottom: 80px;
}

.back-button2{
  position: absolute;
  z-index: 9999;
  left: 16px;
}
</style>